import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "modalButton", "directButton"];

  static values = {
    wasChanged: Boolean,
    originalId: Number
  };

  selected() {
    if (this.currentSelectionIsSameAsOriginal()) {
      this.modalButtonTarget.classList.add("hidden");
      this.directButtonTarget.classList.remove("hidden");
    } else {
      this.modalButtonTarget.classList.remove("hidden");
      this.directButtonTarget.classList.add("hidden");
    }
  }

  currentSelectionIsSameAsOriginal() {
    return (this.selectTarget.value == this.originalIdValue);
  }
}
