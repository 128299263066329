import {Controller} from "@hotwired/stimulus";
import Swiper from "swiper";
import {Navigation} from "swiper";


export default class extends Controller {
  static targets = ["wrapper", "slide", "next", "previous"];

  connect() {
    if (
      window.matchMedia("(hover: none) and (pointer: coarse)").matches != true
    ) {
      new Swiper(this.element, {
        speed: 400,
        slidesPerGroupAuto: true,
        slidesPerView: "auto",
        allowTouchMove: true,
        navigation: {
          nextEl: this.nextTarget,
          prevEl: this.previousTarget,
          disabledClass: "opacity-0",
        },
        modules: [Navigation],
      });
    } else {
      let sliders = document.querySelectorAll(".swiper-wrapper");
      sliders.forEach((slider) => {
        slider.classList.add("scrolling-enabled");
      });
      let sliderStart = document.querySelectorAll("[data-slider-target='previous']");
      let sliderEnd = document.querySelectorAll("[data-slider-target='next']");
      sliderStart.forEach((button) => {
        button.disabled = true;
      });
      sliderEnd.forEach((button) => {
        button.disabled = true;
      });
    }
  }
}
