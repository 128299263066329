/**
 * Allows the native share menu to be opened
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    text: String,
    url: String
  };

  /**
   * lifecycle
   */

  connect() {
    if (!navigator.share)
      this.element.classList.toggle("hidden", true);
  }

  /**
   * actions
   */

  share() {
    navigator.share({
      url: this.urlValue,
      text: this.textValue
    });
  }
}
