/**
 * Copies a value to the user's clipboard
 * 
 * NOTE The Clipboard API only works in a secure context (HTTPS) when on iOS
 */

import {Controller} from "@hotwired/stimulus";
import Flash from "../lib/flash";

export default class extends Controller {
  static targets = ["text"];
  static values ={
    successMessage:  {type: String, default: "Copied Link!"}
  };
  /**
   * lifecycle
   */

  connect() {
    console.log("hello");
    if (this.hasTextTarget)
      this.originText = this.textTarget.innerText.trim();
  }

  /**
   * actions
   */

  copy(event) {
    const button = event.currentTarget;
    const value = button.getAttribute("data-value");

    if (!navigator.clipboard) {
      Flash.addFlash(
        "Sorry, your browser won't allow us to copy that",
        "error"
      );

      return;

    }

    navigator
      .clipboard
      .writeText(value)
      .then(() => {
        if (this.hasTextTarget) {
          
          this.textTarget.innerText = "Copied!";
  
          if (this.lastCopyButtonTimeout)
            clearTimeout(this.lastCopyButtonTimeout);
  
          this.lastCopyButtonTimeout = setTimeout(() => {
            this.element.classList.remove("success");
            this.textTarget.innerText = this.originText;
          }, 2000);
        } else {
          this.element.classList.add("success");
          Flash.addFlash(
            this.successMessageValue,
            "success"
          );
        }

      }).catch(error => {
        console.error(`unable to copy value '${value}' to clipboard: ${error}`);
        
        if (this.hasTextTarget) {
          this.textTarget.innerText = "Error!";
  
          if (this.lastCopyButtonTimeout)
            clearTimeout(this.lastCopyButtonTimeout);
  
          this.lastCopyButtonTimeout = setTimeout(() => {
            this.textTarget.innerText = "Error!";
          }, 2000);
        }
      });
  }
}
