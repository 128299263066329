import CommandPalette from "../../lib/command_palette";

export default class NativeBridge {
  toggleCommandPalette() {
    if (new CommandPalette.State().shown) {
      CommandPalette.hide();
    } else {
      CommandPalette.show();
    }
  }
}

window.nativeBridge = new NativeBridge();
