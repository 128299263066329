/**
 * Client-side behavior for the assignment show page
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["notifications"];

  /**
   * lifecycle
   */

  disconnect() {
    if (this.observer)
      this.observer.disconnect();
  }

  notificationsTargetConnected(element) {
    /**
     * Add a mutation observer to listen for changes to ensure that we remove the
     * appropriate elements if there are no more notifications left
     */

    this.observer = new MutationObserver(() => {
      if (element.children.length < 1) {
        this.element.remove();
      }
    });

    this.observer.observe(element, {subtree: true, childList: true});
  }
}