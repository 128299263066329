/**
 * Allows creation of interactions from the client
 */

import Rails from "@rails/ujs";

function createButtonInteraction({source, name, location, detail, featureName}) {
  const payload = {
    interaction: {
      type: "Interaction::Button",
      data: JSON.stringify({
        source,
        name,
        location,
        detail,
        feature_name: featureName
      })
    }
  };

  fetch("/interactions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(payload)
  }).then(res => {
    if (res.ok)
      return;

    console.error(`attempted to submit a button interaction name: ${name} but the server responded ${res.status}`);
  }).catch(error => {
    console.error(`attempted to submit a button interaction name: ${name} - error: ${error.message}`);
  });
}

export default {
  createButtonInteraction
};